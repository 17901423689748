import { Spin } from "antd";

export const Loader = ({ loadingMessage = "Chargement" }) => {
  return (
    <div>
      <Spin />
      <p>{loadingMessage}</p>
    </div>
  );
};
