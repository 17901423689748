import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAsync } from "react-use";
import {
  getCounterByAlias,
  updateCounterEvent,
  UpdateCounterEventsOperation,
  useRealTimeCounterValue,
  connectToRealtimeCounter,
} from "../../../api/counters";
import { notifyError, useIsTabletOrMobile } from "../../layout/helpers";
import { Loader } from "../../layout/Loader";
import { MainLayout } from "../../layout/MainLayout";
import { PasswordValidation } from "./PasswordValidation";
import { motion } from "framer-motion";

export const Counter = (): JSX.Element | null => {
  const history = useHistory();
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [counterAccessGranted, setCounterAccessGranted] = useState(false);
  const params: any = useParams();
  const { loading, value, error } = useAsync(() =>
    getCounterByAlias(params.alias)
  );
  const counter = value || null;

  useEffect(() => {
    if (!counter)
      return;

    const params = {
      counter: { id: counter.id, password}
    };
    connectToRealtimeCounter(params)
    .then(() => setCounterAccessGranted(true))
    .catch((accessError) => {
      setCounterAccessGranted(false);
      //TODO: this causes the effect to trigger again with an empty password
      setPassword(undefined);

      if (accessError.type === "FeathersError" && accessError.name === "NotAuthenticated") {
        if (password !== undefined) // avoid displaying error when it's just the effect triggering automatically (see above TODO)
          notifyError(accessError.message);
      }
    });
  }, [ counter, password ]);

  const counterValue = useRealTimeCounterValue(counter);

  const isTabletOrMobile = useIsTabletOrMobile();

  if (loading)
    return (
      <div style={{ textAlign: "center", marginTop: "10vh" }}>
        <Loader loadingMessage="Chargement du compteur" />
      </div>
    );

  if (error) notifyError(error.message);

  if (!counter) {
    history.push("/compteurs/compteur-non-trouve");
    return null;
  }

  const updateCounter = async (operation: UpdateCounterEventsOperation) => {
    try {
      await updateCounterEvent({
        counterId: counter.id,
        operation,
      });
    } catch (error: any) {
      notifyError(error.message);
    }
  };



  return (
    <MainLayout
      hideNavBar={isTabletOrMobile}
      hideSider={isTabletOrMobile}
      title={`${counter.fullName}`}
      publicPage
    >
      {counter.isEnabled === false && (
        <p>
          Ce compteur est désactivé. Pour pouvoir l'utiliser, veuillez l'activer
          dans vos paramètres
        </p>
      )}
      {counter.hasPassword && counter.passwordLength && !counterAccessGranted ? (
        <PasswordValidation
          passwordLength={ counter.passwordLength }
          currentPassword= { password }
          setCurrentPassword={ setPassword }
        />
      ) : (
        <div className="counter-grid">
          <div className="counter-total">{counterValue}</div>

          <motion.button
            whileTap={{ scale: 0.95 }}
            className="counter-plus-btn counter-btn"
            onClick={() => updateCounter("ENTER")}
            disabled={counter.isEnabled === false}
          >
            +1
          </motion.button>
          <motion.button
            whileTap={{ scale: 0.95 }}
            className="counter-minus-btn counter-btn"
            onClick={() => updateCounter("EXIT")}
            disabled={counter.isEnabled === false}
          >
            -1
          </motion.button>
        </div>
      )}
    </MainLayout>
  );
};
