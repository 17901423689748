import { useState } from "react";
import { useToggle } from "react-use";
import { useCurrentUser } from "../../../api/auth";
import { useCounters } from "../../../api/counters";
import { Counter } from "../../../types";
import { CounterFormModal } from "../../layout/CounterFormModal";
import { notifyError } from "../../layout/helpers";
import { MainLayout } from "../../layout/MainLayout";
import { CounterRow } from "./CounterRow";

export const CounterList = () => {
  const [showCounterFormModal, toggleCounterFormModal] = useToggle(false);
  const [counterToEdit, setCounterToEdit] = useState<Counter | null>(null);
  const currentUser = useCurrentUser();

  const { data: counters, error, refetch } = useCounters();

  const enabledCounters = counters?.filter((x) => x.isEnabled);

  const MAX_COUNTERS_FOR_CUSTOMER = currentUser?.customer?.counterAmount;

  const canCreateCounter = enabledCounters
    ? enabledCounters.length < MAX_COUNTERS_FOR_CUSTOMER
    : false;

  return (
    <MainLayout
      title="Liste des compteurs"
      subTitle={`${enabledCounters?.length} / ${MAX_COUNTERS_FOR_CUSTOMER} compteurs actifs`}
      actions={[
        {
          label: "Ajouter un compteur",
          disabled: !canCreateCounter,
          isPrimary: true,
          callback: toggleCounterFormModal,
        },
      ]}
    >
      {error && notifyError(error)}
      {counters?.length ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {counters
            .sort((a, b) => a.fullName.localeCompare(b.fullName))
            .map((counter) => (
              <CounterRow
                counter={counter}
                refetch={refetch}
                setCounterToEdit={setCounterToEdit}
                toggleCounterFormModal={toggleCounterFormModal}
                key={counter.id}
              />
            ))}
        </div>
      ) : (
        <p>Vous n'avez encore de compteurs</p>
      )}
      <CounterFormModal
        show={showCounterFormModal}
        toggle={() => {
          toggleCounterFormModal();
          setCounterToEdit(null);
        }}
        counter={counterToEdit}
        refetch={refetch}
      />
    </MainLayout>
  );
};
