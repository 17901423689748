import { LoginForm } from "../LoginForm";
import { MainLayout } from "./MainLayout";

export const LoginPage = () => {
  return (
    <MainLayout>
      <h1
        style={{
          textAlign: "center",
          marginBottom: "50px",
          fontSize: "1.4rem",
        }}
      >
        Veuillez vous connecter pour accéder à vos compteurs
      </h1>
      <div style={{ width: "400px", margin: "auto" }}>
        <LoginForm displaySubmitButton />
      </div>
    </MainLayout>
  );
};
