import { CSSProperties, ReactNode, useState } from "react";
import { Layout, Menu, PageHeader, Button } from "antd";
import { LogoutOutlined, LoginOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { useToggle } from "react-use";
import { LoginModal } from "./LoginModal";
import { logOut, useCurrentUser, useUserIsLogged } from "../../api/auth";
import { DropdownMenu } from "./DropdownMenu";
import { useIsMobile, useIsTabletOrMobile } from "./helpers";

const { Header, Content } = Layout;

export type Action = {
  label: string;
  isPrimary?: boolean;
  callback: () => void;
  disabled?: boolean;
};

type Props = {
  children: ReactNode;
  actions?: Action[];
  title?: string;
  subTitle?: string;
  publicPage?: boolean;
  hideNavBar?: boolean;
  hideSider?: boolean;
  style?: CSSProperties;
};

export const MainLayout = ({
  children,
  actions,
  title,
  subTitle,
  publicPage = false,
  hideNavBar = false,
  hideSider = false,
  style,
}: Props) => {
  const [currentPageKey, setCurrentPageKey] = useState<string>("");
  const [showLoginModal, toggleLoginModal] = useToggle(false);
  const userIsLoggedIn = useUserIsLogged();
  const currentUser = useCurrentUser();
  const history = useHistory();

  const isTabletOrMobile = useIsTabletOrMobile();
  const isMobile = useIsMobile();

  if (!publicPage && !userIsLoggedIn) {
    history.push("/");
  }

  const displayButtons = (): any[] => {
    const buttons: any[] = [];
    const primaryButton = (actions || []).find((x) => x.isPrimary);
    primaryButton &&
      buttons.push(
        <Button
          key="primary"
          type={"primary"}
          onClick={primaryButton.callback}
          disabled={primaryButton.disabled}
        >
          {primaryButton.label}
        </Button>
      );

    const secondaryActions = actions?.filter((x) => !x.isPrimary);

    if (secondaryActions?.length) {
      buttons.push(
        <DropdownMenu key="more" secondaryActions={secondaryActions} />
      );
    }
    return buttons;
  };

  const finalStyle: CSSProperties = {
    minHeight: "100vh",
    overflowX: "hidden",
    ...style,
  };

  return (
    <>
      <Layout style={finalStyle}>
        <Header className="header" hidden={hideNavBar}>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[currentPageKey]}
            onClick={({ key }) => setCurrentPageKey(key)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100vw",
                marginLeft: "20px",
              }}
            >
              <div style={{ display: "flex", width: "65vw" }}>
                <Link
                  to="/compteurs"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <span style={{ fontSize: "120%" }}>Little Big Compteurs</span>
                  {!isMobile && currentUser?.customer?.name && (
                    <>
                      <span> | </span>
                      <span style={{ fontSize: "120%", color: "orange" }}>
                        {currentUser?.customer?.name}
                      </span>
                    </>
                  )}
                </Link>
              </div>
              {userIsLoggedIn ? (
                <Menu.Item
                  style={{ marginLeft: "auto" }}
                  key="logout"
                  onClick={() => logOut(history)}
                  icon={isTabletOrMobile && <LogoutOutlined />}
                >
                  {!isTabletOrMobile && "Déconnexion"}
                </Menu.Item>
              ) : (
                <Menu.Item
                  style={{ marginLeft: "auto" }}
                  key="login"
                  onClick={toggleLoginModal}
                  icon={<LoginOutlined />}
                >
                  {!isTabletOrMobile && "Connexion"}
                </Menu.Item>
              )}
            </div>
          </Menu>
        </Header>
        <Layout>
          <PageHeader
            title={title}
            className="site-page-header"
            extra={displayButtons()}
          />
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <>
              <p style={{ fontSize: "1.2rem" }}>{subTitle} </p>
              {children}
            </>
          </Content>
        </Layout>
      </Layout>
      <LoginModal show={showLoginModal} toggle={toggleLoginModal} />
    </>
  );
};
