import { useRecoilValue } from "recoil";
import { userState } from "../store";
import { isEmpty } from "lodash";
import { getFeatherApp } from "./commons";

type LoginProps = {
  email: string;
  password: string;
};

const app = getFeatherApp();
export const AUTH_STRATEGY = "local";

export const reAuth = async () => {
  if (!localStorage.getItem("compteurs-token")) return;
  const user = await app.reAuthenticate();
  return user;
};

export const login = async ({ email, password }: LoginProps) => {
  const res = await app.authenticate({
    strategy: AUTH_STRATEGY,
    email,
    password,
  });
  return res;
};

export const logOut = (history: any) => {
  localStorage.removeItem("compteurs-token");
  history.push("/");
  window.location.reload();
};

export const useUserIsLogged = () => {
  const user = useRecoilValue(userState);
  return !isEmpty(user);
};

export const useCurrentUser = () => useRecoilValue(userState);
