import { Button, Dropdown, Menu } from "antd";
import { Action } from "./MainLayout";

const getSecondaryActions = (secondaryActions: Action[]) => {
  return (
    <Menu>
      {secondaryActions.map((action) => (
        <Menu.Item onClick={action.callback} disabled={action.disabled}>
          {action.label}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export const DropdownMenu = ({
  secondaryActions,
}: {
  secondaryActions: Action[];
}) => {
  return (
    <Dropdown key="more" overlay={getSecondaryActions(secondaryActions)}>
      <Button
        style={{
          border: "none",
          padding: 0,
        }}
      >
        <Button>Actions</Button>
      </Button>
    </Dropdown>
  );
};
