import { Modal } from "antd";
import { LoginForm } from "../LoginForm";
import { useForm } from "react-hook-form";

type Props = {
  show: boolean;
  toggle: () => void;
};

export const LoginModal = ({ show, toggle }: Props) => {
  const { clearErrors } = useForm();

  const handleClose = () => {
    clearErrors();
    toggle();
  };

  return (
    <Modal
      title="Connexion"
      visible={show}
      onCancel={handleClose}
      okButtonProps={{ form: "loginForm", htmlType: "submit" }}
      okText="Connexion"
      cancelText="Annuler"
    >
      <LoginForm onSuccess={handleClose} />
    </Modal>
  );
};
