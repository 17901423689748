import { Button, Input } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { login } from "../api/auth";
import { userState } from "../store";
import { notifyError } from "./layout/helpers";

type Props = {
  onSuccess?: () => void;
  displaySubmitButton?: boolean;
};

export const LoginForm = ({
  onSuccess,
  displaySubmitButton = false,
}: Props) => {
  const setUserState = useSetRecoilState(userState);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const onSubmit = async (formData: any) => {
    const { email, password } = formData;
    try {
      const res = await login({
        email,
        password,
      });
      setUserState(res.user);
      !!onSuccess && onSuccess();
    } catch (e: any) {
      notifyError("Erreur lors de l'authentification");
      console.error(e.message);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id="loginForm"
      style={{ width: "80%" }}
    >
      <label htmlFor="email">Email</label>
      <Controller
        control={control}
        rules={{
          required: { value: true, message: "L'email est requis" },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Veuillez entrer une adresse email valide",
          },
        }}
        name="email"
        render={({ field }) => (
          <Input placeholder="Email" id="email" {...field} />
        )}
      />
      <label htmlFor="password">Mot de passe</label>
      <Controller
        control={control}
        rules={{
          required: { value: true, message: "Le mot de passe est requis" },
        }}
        name="password"
        render={({ field }) => (
          <Input.Password placeholder="Mot de passe" id="password" {...field} />
        )}
      />
      {errors.email && <p>{errors.email?.message}</p>}
      {errors.password && <p>{errors.password?.message}</p>}
      {displaySubmitButton && (
        <Button htmlType="submit" type="primary" style={{ marginTop: "30px" }}>
          Connexion
        </Button>
      )}
    </form>
  );
};
