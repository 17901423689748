import { useEffect, useState } from "react";
import { saveAs } from 'file-saver';
import { Counter } from "../types";
import { getFeatherApp, useFetch, SERVER_URL } from "./commons";

const COUNTER_CONFIGS = "counter-configs";
const COUNTER_REALTIME_VALUES = "counter-realtime-values";
const COUNTER_NAMES = "counter-names";
const COUNTER_ACCESS = "counter-access";

export const useCounters = () =>
  useFetch<Counter[]>({
    service: COUNTER_CONFIGS,
    queryType: "find",
  });

export const getCounterByAlias = async (
  alias: string
): Promise<null | Counter> => {
  if (!alias) return null;
  return getFeatherApp().service(COUNTER_NAMES).get(alias);
};

type CounterAccessParams = {
  counter: {
    id: number,
    password?: string,
  }
}

export const connectToRealtimeCounter = async (params: CounterAccessParams) => {
  return getFeatherApp().service(COUNTER_ACCESS).create(params);
}

export const checkIfUrlNameIsUnique = async (
  urlName: string,
  editingCounterId?: number
) => {
  if (!urlName) return true;

  //page déjà utilisée par le router
  if (urlName === "compteurs") return false;

  const counterWithSameUrl = await getCounterByAlias(urlName);

  // si pas dautres compteurs avec la meme URL => OK ✔️
  if (!counterWithSameUrl) return true;
  // si 1 compteur et que c'est lui meme (edit) => OK ✔️
  if (editingCounterId && counterWithSameUrl.id === editingCounterId)
    return true;
  // Sinon, pas OK ❌
  return false;
};

export const getInitialCounterValue = async (counterId: number | undefined) => {
  if (!counterId) return;
  return getFeatherApp().service(COUNTER_REALTIME_VALUES).get(counterId);
};

type RealTimeDataRes = {
  id: number;
  value: number;
  isEnabled: boolean;
};

export const subscribeToRealTimeCounterValue = async (
  onCreated: (data: RealTimeDataRes) => void
) => {
  getFeatherApp()
    .service(COUNTER_REALTIME_VALUES)
    .on("created", (data: RealTimeDataRes) => {
      onCreated(data);
    });
};

export const useRealTimeCounterValue = (counter: Counter | null) => {
  const [counterValue, setCounterValue] = useState<number | null>(null);

  //get initial value
  useEffect(() => {
    if (!counter?.id) return;
    (async () => {
      const { value } = await getInitialCounterValue(counter?.id);
      setCounterValue(value);
    })();
  }, [counter, setCounterValue]);

  //subscribe to updates
  useEffect(() => {
    if (!counter?.id) return;
    subscribeToRealTimeCounterValue((data) => {
      if (data.id === counter.id) setCounterValue(data.value);
    });
  }, [counter?.id, setCounterValue]);

  return counterValue;
};

export type UpdateCounterEventsOperation = "ENTER" | "EXIT";

type UpdateCounterEventProps = {
  counterId: number;
  operation: UpdateCounterEventsOperation;
};

export const updateCounterEvent = async ({
  counterId,
  operation,
}: UpdateCounterEventProps) =>
  getFeatherApp().service(COUNTER_REALTIME_VALUES).create({
    counterId,
    type: operation,
    typology: null,
  });

export const saveCounter = async (counter: Omit<Counter, "id" | "hasPassword" | "passwordLength">) => {
  return getFeatherApp().service(COUNTER_CONFIGS).create(counter);
};

export const updateCounter = async (counter: Counter) => {
  return getFeatherApp().service(COUNTER_CONFIGS).update(counter.id, counter);
};

export const deleteCounter = async (counterId: Counter["id"]) => {
  return getFeatherApp().service(COUNTER_CONFIGS).remove(counterId);
};

type DownloadStatsParams = {
  counterId: number,
  start: Date | string | number,
  stop: Date | string | number,
  format: "csv" | "json"
}

export const downloadStats = async (counter: Counter, params: DownloadStatsParams) => {
  const res = await fetch(SERVER_URL + "/counter-stats-export", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('compteurs-token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params)
  });

  console.log(res);
  const blob = await res.blob();
  saveAs(blob, `counter-${counter.urlName}.csv`);
}

