import { Button, Popconfirm } from "antd";
import { EyeOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Counter } from "../../../types";
import { useIsTabletOrMobile } from "../../layout/helpers";

type Props = {
  counter: Counter;
  onEdit: () => void;
  onDelete: () => void;
};

export const CounterActionsButtons = ({ counter, onEdit, onDelete }: Props) => {
  const history = useHistory();
  const defaultBtnStyle = {
    margin: "10px",
  };
  const isTabletOrMobile = useIsTabletOrMobile();

  return (
    <div
      style={{
        display: "flex",
        marginTop: "20px",
        justifyContent: "space-around",
        flexWrap: "wrap",
        width: "100%",
      }}
    >
      <div>
        <Button
          style={defaultBtnStyle}
          shape="round"
          icon={<EyeOutlined />}
          onClick={() => history.push(`/${counter.urlName}`)}
          title="Accéder"
          type="primary"
        >
          {!isTabletOrMobile && "Accéder"}
        </Button>
      </div>

      <Button
        style={defaultBtnStyle}
        shape="round"
        icon={<EditOutlined />}
        onClick={onEdit}
        title="Éditer"
      >
        {!isTabletOrMobile && "Éditer"}
      </Button>
      <Popconfirm
        title={`Etes-vous sur de vouloir supprimer le compteur ${counter.fullName}?`}
        okText="Oui"
        cancelText="Annuler"
        onConfirm={onDelete}
        placement="topLeft"
      >
        <Button
          style={defaultBtnStyle}
          shape="round"
          type="primary"
          danger
          icon={<DeleteOutlined />}
          title="Supprimer"
        >
          {!isTabletOrMobile && "Supprimer"}
        </Button>
      </Popconfirm>
    </div>
  );
};
