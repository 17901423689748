import "antd/dist/antd.css";

import { Switch, Route, useHistory } from "react-router-dom";

import { CounterList } from "./components/counters/List/CounterList";

import { LoginPage } from "./components/layout/LoginPage";
import { reAuth, useUserIsLogged } from "./api/auth";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { userState } from "./store";
import { Counter } from "./components/counters/Counter/Counter";
import { notifyError } from "./components/layout/helpers";
import { ErrorPage } from "./components/layout/ErrorPage";

const App = () => {
  const userIsLoggedIn = useUserIsLogged();
  const setUserState = useSetRecoilState(userState);
  const history = useHistory();

  useEffect(() => {
    if (userIsLoggedIn) return;
    (async () => {
      try {
        const user = await reAuth();
        user && setUserState(user.user);
      } catch (error) {
        notifyError("Erreur lors de l'authentification");
      }
    })();
  }, [setUserState, history, userIsLoggedIn]);

  return (
    <Switch>
      <Route exact path="/compteurs">
        <CounterList />
      </Route>
      <Route exact path="/:alias">
        <Counter />
      </Route>
      <Route exact path="/">
        {userIsLoggedIn ? <CounterList /> : <LoginPage />}
      </Route>
      <Route exact path="/compteurs/compteur-non-trouve">
        <ErrorPage title="Impossible de trouver ce compteur" isPublicPage />
      </Route>
      <Route>
        <ErrorPage title="Cette page n'existe pas 🤔" isPublicPage />
      </Route>
    </Switch>
  );
};

export default App;
