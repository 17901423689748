import { Button, Input, InputNumber, Modal, Space } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDebounce } from "react-use";
import { useCurrentUser } from "../../api/auth";
import {
  checkIfUrlNameIsUnique,
  saveCounter,
  updateCounter,
} from "../../api/counters";
import { slugify } from "../../helpers/Slugify";
import { Counter } from "../../types";
import { notifyError } from "./helpers";

type Props = {
  show: boolean;
  toggle: () => void;
  counter?: Counter | null;
  onClose?: () => void;
  refetch: () => void;
};

type FormValues = {
  fullName: string;
  limit: number;
  urlName: string;
  isEnabled: boolean;
  password?: string;
};

export const CounterFormModal = ({
  show,
  toggle,
  counter,
  onClose,
  refetch,
}: Props) => {
  const defaultValues = useMemo(
    () => ({
      fullName: counter?.fullName,
      isEnabled: counter?.isEnabled,
      limit: counter?.limit,
      urlName: counter?.urlName,
      password: counter?.password,
    }),
    [
      counter?.fullName,
      counter?.isEnabled,
      counter?.limit,
      counter?.password,
      counter?.urlName,
    ]
  );
  const currentUser = useCurrentUser();
  const isEdit = !!counter?.id;

  const [availableUrlName, setAvailableUrlName] = useState<boolean | null>(
    null
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
    reset,
    register,
  } = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
    setAvailableUrlName(null);
  }, [defaultValues, reset]);

  useDebounce(
    async () => {
      const currentUrlName = watch("urlName");
      if (!currentUrlName?.length) {
        setAvailableUrlName(null);
        return;
      }
      if (watch("urlName") === counter?.urlName) {
        setAvailableUrlName(null);
        return;
      }
      const urlIsAvailable = await checkIfUrlNameIsUnique(
        currentUrlName,
        counter?.id
      );
      setAvailableUrlName(urlIsAvailable);
    },
    500,
    [watch("urlName")]
  );

  const onSubmit = async (formData: FormValues) => {
    try {
      if (isEdit) {
        await updateCounter({
          ...counter,
          ...formData,
          customerId: currentUser.customerId,
        });
      } else {
        await saveCounter({
          ...formData,
          customerId: currentUser.customerId,
        });
      }
      refetch();
      handleClose();
    } catch (error: any) {
      //TODO meilleure gestion des erreurs (ex : contrainte d'unicité)
      notifyError("Erreur lors de la sauvegarde du compteur");
      console.error(error?.message);
    }
  };

  const handleClose = () => {
    clearErrors();
    onClose && onClose();
    toggle();
  };

  const handleChangeUrl = async (e: any) => {
    const counterUrl = slugify(e.target.value);
    setValue("urlName", counterUrl);
  };

  const handleChangeName = (e: any) => {
    setValue("fullName", e.target.value);

    handleChangeUrl(e);
  };

  const displayAvailableUrlStatus = () => {
    if (availableUrlName === null) return null;
    return (
      !availableUrlName &&
      "Cette url est déjà utilisée 😟. Veuillez en saisir une différente"
    );
  };

  return (
    <Modal
      title={
        isEdit
          ? `Modifier le compteur ${counter.fullName}`
          : "Créer un compteur"
      }
      visible={show}
      onCancel={handleClose}
      okButtonProps={{
        form: "addCounterForm",
        htmlType: "submit",
        disabled: !isEmpty(errors) || availableUrlName === false,
      }}
      okText={isEdit ? "Modifier" : "Ajouter"}
      cancelText="Annuler"
    >
      <form onSubmit={handleSubmit(onSubmit)} id="addCounterForm">
        <Space direction="vertical" size="middle">
          <p>
            Les champs marqués d'un astérisque{" "}
            <sup style={{ color: "red" }}>*</sup> sont obligatoires.
          </p>
          <div className="form-group">
            <label htmlFor="fullName">
              Nom du compteur <sup style={{ color: "red" }}>*</sup>
            </label>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Le nom du compteur est requis",
                },
              }}
              name="fullName"
              render={({ field }) => (
                <Input
                  placeholder="Nom du compteur"
                  id="fullName"
                  {...field}
                  onChange={handleChangeName}
                />
              )}
            />
            {errors.fullName && (
              <p style={{ marginBottom: 0, color: "red" }}>
                {errors.fullName?.message}
              </p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="limit">
              Limite de personne <sup style={{ color: "red" }}>*</sup>
            </label>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "La limite de personne est requise",
                },
                min: {
                  value: 1,
                  message: "La limite de personne est d'au moins de 1 personne",
                },
              }}
              name="limit"
              render={({ field }) => (
                <InputNumber
                  placeholder="21"
                  id="limit"
                  min={1}
                  style={{
                    width: "100%",
                  }}
                  {...field}
                />
              )}
            />
            {errors.limit && (
              <p style={{ marginBottom: 0, color: "red" }}>
                {errors.limit?.message}
              </p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="urlName">
              Url du compteur <sup style={{ color: "red" }}>*</sup>
            </label>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "L'url du compteur est requise",
                },
              }}
              name="urlName"
              render={({ field }) => (
                <>
                  <Input
                    placeholder="place-des-elephants"
                    id="urlName"
                    addonBefore="cpt73.fr/"
                    {...field}
                    onChange={handleChangeUrl}
                  />
                  <span
                    style={{
                      color: availableUrlName ? "green" : "red",
                    }}
                  >
                    {displayAvailableUrlStatus()}
                  </span>
                </>
              )}
            />
            {errors.urlName && (
              <p style={{ marginBottom: 0, color: "red" }}>
                {errors.urlName?.message}
              </p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="password">
              Mot de passe d'accès au compteur (facultatif)
            </label>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <Input
                  type="text"
                  id="password"
                  {...field}
                  onChange={(e) =>
                    setValue("password", e.target.value.toUpperCase())
                  }
                />
              )}
            />
          </div>

          {watch("password") && (
            <Button onClick={() => setValue("password", undefined)}>
              Supprimer le mot de passe{" "}
            </Button>
          )}

          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <label htmlFor="isEnabled" style={{ marginRight: "20px" }}>
              Compteur actif
            </label>
            <input
              type="checkbox"
              placeholder="isEnabled"
              {...register("isEnabled", {})}
            />
          </div>
        </Space>
      </form>
    </Modal>
  );
};
