import { notification } from "antd";
import { useMediaQuery } from "react-responsive";

export const openNotificationWithIcon = (
  type: "success" | "info" | "warning" | "error",
  title: string,
  message?: string
) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export const notifyError = (message: string) =>
  openNotificationWithIcon("error", message);

export const notifySuccess = (message: string) =>
  openNotificationWithIcon("success", message);

export const useIsTabletOrMobile = () =>
  useMediaQuery({
    query: "(max-width: 1224px)",
  });

export const useIsMobile = () =>
  useMediaQuery({
    query: "(max-width: 600px)",
  });
