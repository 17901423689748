import { Button, Card, Popconfirm } from "antd";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { deleteCounter, useRealTimeCounterValue, downloadStats } from "../../../api/counters";
import { Counter } from "../../../types";
import { notifyError, useIsMobile } from "../../layout/helpers";
import { CounterActionsButtons } from "./CounterActionsButtons";

type Props = {
  counter: Counter;
  refetch: () => void;
  setCounterToEdit: Dispatch<SetStateAction<Counter | null>>;
  toggleCounterFormModal: (nextValue?: any) => void;
};

export const CounterRow = ({
  counter,
  refetch,
  setCounterToEdit,
  toggleCounterFormModal,
}: Props) => {
  const counterValue = useRealTimeCounterValue(counter);
  const isMobile = useIsMobile();

  const handleDelete = async (counter: Counter) => {
    try {
      await deleteCounter(counter.id);
      refetch();
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  const percent = counterValue
    ? Math.round((counterValue / counter.limit) * 100)
    : 0;
  const THRESHOLD = 75;
  return (
    <Card
      key={counter.id}
      title={<h4>{counter.fullName}</h4>}
      extra={
        <Popconfirm
          title={ () => (
            <p>
              Une vue détaillée des statistiques sera disponible dans une prochaine version.
              <br />Vous pouvez toujours télécharger les stats du compteur ci-dessous :
              <div>
                <Button
                  onClick={() => downloadStats(counter, {format: "csv", start: 0, stop: new Date().toISOString(), counterId: counter.id}) }>
                  csv
                </Button>
              </div>
            </p>
          )}
          okText="Ok"
          cancelText="Annuler"
          placement="topLeft"
        >
          <Link to="/compteurs">Statistiques</Link>
        </Popconfirm>
      }
      bordered
      style={{
        backgroundColor: "#00000",
        borderRadius: "9px",
        width: isMobile ? "90vw" : "500px",
        marginRight: isMobile ? 0 : "30px",
        marginBottom: "30px",
      }}
    >
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ marginRight: "150px" }}>
          <li
            style={{
              listStyle: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-around",
              width: "300px",
            }}
          >
            <p style={{ marginRight: "30px", fontSize: "1rem" }}>
              Capacité : {`${counterValue}`} / {counter.limit} (
              <span style={{ color: percent >= THRESHOLD ? "red" : "initial" }}>
                {percent}%
              </span>
              )
            </p>
            <p>Statut : {counter.isEnabled ? "Actif ✔️" : "Désactivé ❌"}</p>
          </li>
        </div>

        <CounterActionsButtons
          counter={counter}
          onEdit={() => {
            setCounterToEdit(counter);
            toggleCounterFormModal();
          }}
          onDelete={() => handleDelete(counter)}
        />
      </div>
    </Card>
  );
};
