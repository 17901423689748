import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import ReactPinField from "react-pin-field";
import { notifyError } from "../../layout/helpers";

type Props = {
  passwordLength: number;
  currentPassword?: string,
  setCurrentPassword: Dispatch<SetStateAction<string | undefined>>;
};

export const PasswordValidation = ({
  passwordLength,
  currentPassword,
  setCurrentPassword,
}: Props) => {
  const pinFieldRef = useRef() as any;

  const setFields = (str: string) => {
    const inputs: HTMLInputElement[] = (pinFieldRef?.current?.inputs || []);
    inputs.forEach((input, i) => input.value = str[i] || "")
  }

  const resetFields = () => setFields("");
  const focusFirstField = () => pinFieldRef?.current?.inputs[0].focus();
  const focusFirstEmptyField = () => pinFieldRef?.current?.inputs.find((e: HTMLInputElement) => e.value === "")?.focus();


  useEffect(
    () => {
      setFields(currentPassword || "");
      focusFirstEmptyField();
    },
    [ currentPassword ]
  );

  useEffect( focusFirstField, [] )

  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p style={{ fontSize: "1.3rem" }}>
        Veuillez entrer le mot de passe pour accéder au compteur
      </p>
      <ReactPinField
        style={{ marginLeft: "auto" }}
        ref={ pinFieldRef }
        className="pin-field"
        length={ passwordLength }
        onComplete={ setCurrentPassword }
        format={(k) => k.toUpperCase()}
      />
    </div>
  );
};
