import { MainLayout } from "./MainLayout";

type Props = {
  title: string;
  isPublicPage: boolean;
  children?: JSX.Element;
};

export const ErrorPage = ({
  title,
  isPublicPage,
  children = (
    <p>
      Veuillez vérifier l'url ou nous contacter
      <a href="mailto:littlebigasso@gmail.com"> par email</a>
    </p>
  ),
}: Props) => {
  return (
    <MainLayout title={title} publicPage={isPublicPage}>
      {children}
    </MainLayout>
  );
};
