import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";
import io from "socket.io-client";
import { useCallback, useEffect, useState } from "react";
import { get } from "lodash";

// get the URL from the index.html file, filled by the docker-entrypoint.sh script, called when the container starts
// tell typescript that window.REACT_APP_BACKEND_HOST might exist
declare global {
    interface Window {
      REACT_APP_BACKEND_HOST: string | undefined;
    }
}
export const SERVER_URL = window.REACT_APP_BACKEND_HOST || process.env.REACT_APP_BACKEND_HOST;
if (!SERVER_URL) throw new Error("REACT_APP_BACKEND_HOST is not defined"); //throwing might not be the best thing to do

const socket = io(SERVER_URL);
const app = feathers();

// Setup the transport (Rest, Socket, etc.) here
app.configure(socketio(socket));
app.configure(auth({ storageKey: "compteurs-token" }));

export const getFeatherApp = () => app;

type Props<T> = {
  service: "counter-configs" | "counter-events" | "counter-realtime-values";
  queryType: "find" | "get";
  body?: T;
  dataPath?: string;
  dataLabel?: string;
};

export const useFetch = <T>({ service, queryType, dataPath }: Props<T>) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetch = useCallback(
    () => getFeatherApp().service(service)[queryType](),
    [queryType, service]
  );

  const run = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetch();
      const data: T = dataPath ? get(res, dataPath) : res;
      setData(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error?.message);
    }
  }, [dataPath, fetch]);

  useEffect(() => {
    (async () => {
      run();
    })();
    return () => {
      setData(null);
      setLoading(false);
      setError(null);
    };
  }, [fetch, dataPath, run]);

  return {
    loading,
    data,
    error,
    refetch: run,
  };
};
